import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import { selectExperiment } from 'src/common/selectors/config';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/config';
import { mintSingleton } from '../../../client/mint';
import DialogDisplayAd from './DialogDisplayAd';
import css from './now-playing-dialog-ad.module.scss';

export class NowPlayingDialogAd extends Component {
  static propTypes = {
    matchUrl: PropTypes.string.isRequired,
    guideId: PropTypes.string,
    loadedDisplaySlotId: PropTypes.string.isRequired,
    isFreestarEnabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isAdOpen: false };
    this.handleAdClose = this.handleAdClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loadedDisplaySlotId } = this.props;

    if (
      prevProps.loadedDisplaySlotId !== loadedDisplaySlotId &&
      loadedDisplaySlotId === gptSlotNames.nowPlaying_side
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isAdOpen: true });
    }
  }

  handleAdClose() {
    mintSingleton.instance?.clearSlot(gptSlotNames.nowPlaying_side, true);
    this.setState({ isAdOpen: false });
  }

  render() {
    const { matchUrl, guideId, isFreestarEnabled } = this.props;

    const { isAdOpen } = this.state;

    return (
      <div
        data-testid="adOverlay"
        className={classNames({
          [css.adOverlay]: isAdOpen,
          [css.hiddenAdOverlay]: !isAdOpen,
        })}
      >
        <div className={css.adInnerContainer}>
          <div
            data-testid="closeIconContainer"
            className={css.adCloseIconContainer}
          >
            <i
              data-testid="adCloseIcon"
              className={css.adCloseIcon}
              onClick={this.handleAdClose}
            />
          </div>
          <DialogDisplayAd
            isProfile={false}
            matchUrl={matchUrl}
            guideId={guideId}
            className={css.ad}
            {...getDisplayAdAttributes(
              gptSlotNames.nowPlaying_side,
              isFreestarEnabled,
            )}
          />
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    loadedDisplaySlotId: get(state, 'mint.loadedDisplaySlotId', ''),
    isFreestarEnabled: selectExperiment(state, FREESTAR_ENABLED),
  };
}

export default connect(mapStateToProps)(NowPlayingDialogAd);
